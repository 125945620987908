@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TT Hoves Medium';
  src: url(./fonts/tt_hoves/tt_hoves_medium-webfont.woff2) format('woff2'),
    url(./fonts/tt_hoves/tt_hoves_medium-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves Regular';
  src: url(./fonts/tt_hoves/tt_hoves_regular-webfont.woff2) format('woff2'),
    url(./fonts/tt_hoves/tt_hoves_regular-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: 'TT Hoves Regular';
  overflow-y: scroll;
}

:root {
  --background-color: #f2f0ea;
  --background-color-light: #fcfaf4;

  --primary-color: #f5c543;
  --primary-text-color: #212121;
  --secondary-text-color: #525252;
  --tertiary-text-color: #b6b3ac;

  --primary-button-color: var(--primary-color);
  --secondary-button-color: #212121;
  --secondary-button-text-color: #fff;
  --disabled-button-color: #d8d6d1;
  --disabled-button-text-color: #9e9d98;

  --error-color: #ed766b;
}

.phone {
  width: 280px;
  height: 497px;
  border: 5px solid FE5448;
  border-radius: 45px;
  text-align: center;

  .profile-pic {
    width: 90px;
    margin-top: 20px;
    border-radius: 100px;
  }
}

* {
  color: #212121;
}

.bg-green-500 {
  background-color: #10b981;

  &:hover {
    background-color: #10b9a1;
  }
}

.bg-red-500 {
  background-color: #ef4444;

  &:hover {
    background-color: #c44;
  }
}
